import React, { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import LayoutV3 from "layouts/layout-v3/DashboardLayout";
import LoadingScreen from "../components/LoadingScreen";
import ProtectedRoute from "../pages/ProtectedRoute/ProtectedRoute";
import About from "../pages/about";
import Privacy from "../pages/privacy";

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) => {
	return (
		<Suspense fallback={<LoadingScreen />}>
			<Component {...props} />
		</Suspense>
	);
};

const Watchlist = Loadable(lazy(() => import("pages/dashboards/saas")));
const CrmPage = Loadable(lazy(() => import("pages/dashboards/crmPage")));
const Client = Loadable(lazy(() => import("pages/accounts/account")));

const Login = Loadable(lazy(() => import("pages/authentication/login")));
const Error = Loadable(lazy(() => import("pages/404")));

const Router: FC<any> = ({ clients }) => {
	const { pathname } = useLocation();
	return (
		<Routes>
			<Route path="/login" element={<Login />} />
			<Route path="*" element={<Error />} />
			<Route element={<ProtectedRoute />}>
				<Route
					path="/"
					element={
						<LayoutV3>
							<Watchlist />
						</LayoutV3>
					}
				/>
				<Route
					path="/trading-room"
					element={
						<LayoutV3>
							<Watchlist />
						</LayoutV3>
					}
				/>
				<Route
					path="/client"
					element={
						<LayoutV3>
							<Client />
						</LayoutV3>
					}
				/>
				<Route
					path="/crm"
					element={
						<LayoutV3>
							<CrmPage clients={clients} />
						</LayoutV3>
					}
				/>
				<Route
					path="/about"
					element={
						<LayoutV3>
							<About />
						</LayoutV3>
					}
				/>
				<Route
					path="/privacy"
					element={
						<LayoutV3>
							<Privacy />
						</LayoutV3>
					}
				/>
			</Route>
		</Routes>
	);
};

export default Router;
