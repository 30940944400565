import {
	Box,
	Button,
	ButtonBase,
	CircularProgress,
	Divider,
	Stack,
	styled,
} from "@mui/material";
import AppCheckBox from "components/AppCheckBox";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import { Small } from "components/Typography";
import AuthenticationLayout from "page-sections/authentication/AuthenticationLayout";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ltdLogin } from "../../services/auth/auth.service";
import { jwtDecode } from "jwt-decode";
import useUserData from "../../hooks/api/useUser";
export const CONSTANT_USER = "user";

const Login: FC = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const queryParameters = new URLSearchParams(window.location.search);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errors, setError] = useState({ email: "", password: "", login: "" });
	const [loading, setLoading] = React.useState(false);
	const { data: userData } = useUserData();

	const token = queryParameters.get("access");

	useEffect(() => {
		(async () => {
			try {
				const access: string | null = searchParams.get("access");
				const decocde: any = jwtDecode(access || "");
				const userFirstName = searchParams.get("userFirstName");
				const userLastName = searchParams.get("userLastName");
				const user = {
					access: access,
					user: {
						email: decocde.email,
						firstName: userFirstName,
						lastName: userLastName,
						userId: decocde.user_id,
					},
				};
				window.localStorage.setItem(CONSTANT_USER, JSON.stringify(user));
				navigate("/");
			} catch (e) {
				console.error(e);
			}
		})();
	}, [token]);

	const signIn = async (e: any) => {
		setError({ email: "", password: "", login: "" });
		e.preventDefault();
		if (!!email && !!password) {
			setLoading(true);
			try {
				const user = await ltdLogin(email, password);
				window.localStorage.setItem(CONSTANT_USER, JSON.stringify(user));
				setLoading(false);
				navigate("/");
			} catch (error) {
				const data = { ...errors };
				// @ts-ignore
				data.login = error.toString();
				setError(data);
				setLoading(false);
			}
		} else {
			const data = { ...errors };
			// @ts-ignore
			data.email = !email && "Missing Email";
			// @ts-ignore
			data.password = !password && "Missing Password";
			setError(data);
		}
	};

	return (
		<AuthenticationLayout
			route="/register"
			description="New Here?"
			title="Sign in to Proline"
			routeName="Create an account">
			<form autoComplete="off" onSubmit={(e) => signIn(e)}>
				<Stack gap={2} mt={5}>
					<AppTextField
						fullWidth
						required
						type="email"
						label="Email"
						error={!!errors["email"]}
						helperText={errors["email"]}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<AppTextField
						fullWidth
						required
						type="password"
						label="Password"
						error={!!errors["password"]}
						helperText={errors["password"]}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<FlexBetween>
						<FlexBox alignItems="center" gap={1}>
							<AppCheckBox defaultChecked />
							<Small fontSize={12}>Remember me</Small>
						</FlexBox>

						{/*<Button disableRipple sx={{ mb: 2 }}>*/}
						{/*  <NavLink to="/forget-password" style={{ fontWeight: 600 }}>*/}
						{/*    Forget Password*/}
						{/*  </NavLink>*/}
						{/*</Button>*/}
					</FlexBetween>

					{errors.login && (
						<span style={{ color: "red", fontSize: "14px" }}>{errors.login}</span>
					)}
					<div style={{ width: "100%", position: "relative" }}>
						<Button
							style={{ width: "100%" }}
							disabled={loading}
							variant="contained"
							type="submit">
							Sign In
						</Button>
						{loading && (
							<CircularProgress
								size={24}
								sx={{
									position: "absolute",
									top: "50%",
									left: "50%",
									marginTop: "-12px",
									marginLeft: "-12px",
								}}
							/>
						)}
					</div>
				</Stack>
			</form>
		</AuthenticationLayout>
	);
};

export default Login;
