import ClientStore from "../ClientStore/ClientStore";

export interface IRootStore {
  clientStore:ClientStore
}

class RootStore {
  clientStore:ClientStore
  constructor() {
    this.clientStore = new ClientStore(this)
  }
}

export default new RootStore()
