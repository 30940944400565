export const esTranslations = {
    "CRM": "CRM",
    "Chat": "Chat",
    "Shop": "Tienda",
    "Sales": "Ventas",
    "Payment": "Pago",
    "Account": "Cuenta",
    "Profile": "Perfil",
    "Pricing": "Precios",
    "Invoice": "Factura",
    "Sessions": "Sesiones",
    "Results": "Resultados",
    "Checkout": "Verificar",
    "Dashboards": "Cuadros de mando",
    "Ecommerce": "Comercio electrónico",
    "Upcoming Task": "Próxima tarea",
    "Your Downloads": "Tus descargas",
    "Course Status": "Estado del curso",
    "Course in Progress": "Curso en curso",
    "Enrolled Courses": "Cursos inscritos",
    "Course Completed": "Curso completado",
    "Job Management": "Gestión de trabajos",
    "User & Contact": "Usuario y contacto",
    "User List": "Lista de usuarios",
    "User Grid": "Cuadrícula de usuario",
    "Contact List": "Lista de contactos",
    "Contact Grid": "Cuadrícula de contacto",
    "Invoice List": "Lista de facturas",
    "Create Invoice": "Crear factura",
    "Billing Address": "Dirección de Envio",
    "Payment Complete": "Pago completo",
    "Product List": "Lista de productos",
    "Create Product": "Crear producto",
    "Product Details": "Detalles de producto",
    "Product Grid": "Cuadrícula de productos",
    "Invoice Details": "Detalles de la factura",
    "Learning Management": "Gestión del aprendizaje",
    "Welcome Back! Watson": "¡Bienvenido de nuevo! Watson",
    "Admin Ecommerce": "Administrador de comercio electrónico",
    "Study Time Last Week": "Tiempo de estudio la semana pasada"
}