import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API } from "../../utils/API";
import { ITradeUser } from "../../services/auth/auth.service";

export const CONSTANT_USER = "user";

interface IUser {
	id: number;
	type: string;
	is_demo: boolean;
	balance: number;
	external_account_id: number;
	trading_account_group_id: number;
	margin: string;
	equity: number;
	leverage: number;
	status_code: string;
	turnover: string;
	size: string;
	minimumDeposit: number;
	depositTotal: number;
	withdrawalTotal: number;
	createdAt: string; // ISO 8601 date string
	updatedAt: string; // ISO 8601 date string
	platform: number;
	currency: number;
	customer: number;
	firstName: string;
	email: string;
	lastName: string;
}

const getUser = async (): Promise<IUser> => {
	const USER: any = window.localStorage.getItem(CONSTANT_USER);
	const USER_JSON: ITradeUser = JSON.parse(USER);
	const response = await axios({
		method: "get",
		url: API.user,
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${USER_JSON.access}`,
		},
	});
	return response.data;
};

export default function useUserData() {
	const USER: any = window.localStorage.getItem(CONSTANT_USER);
	const USER_JSON: ITradeUser = JSON.parse(USER);
	return useQuery({
		queryKey: ["user"],
		queryFn: () => getUser(),
		enabled: !!USER_JSON,
	});
}
