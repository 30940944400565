import { makeObservable, observable, action, runInAction } from "mobx";
import { IRootStore } from "../RootStore/RootStore";
import { Client } from "types/clients";
import uniqueId from "utils/generateId";
import { API } from "../../utils/API";

class ClientStore {
  root: IRootStore;
  clients: Client[] = [];
  searchPhrase: string = "";
  selectedRows: number[] = [];

  constructor(root: IRootStore) {
    this.root = root;
    makeObservable(this, {
      clients: observable,
      fetchClients: action,
      searchPhrase: observable,
      selectedRows: observable,
      setSearchPhrase: action,
      setSelectedRows: action,
      addClient: action,
    });
  }
  async fetchClients() {
    try {
      const user: any = localStorage.getItem("user");
      const parsedUser = JSON.parse(user);
      const response = await fetch(API.clients, {
        method: "get",
        headers: {
          Authorization: `Bearer ${parsedUser.token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const body = JSON.parse(await response.text());
      runInAction(() => {
        this.clients = body.data.clients.map((client: any) => {
          return {
            _id: client._id,
            country: client.country,
            campaign: client.campaign,
            sub_campaign_key: client.sub_campaign_key,
            phone: client.phone,
            email: client.email,
            attribution_date: client.attribution_date,
            balance: client.balance,
            sa_balance: client.sa_balance,
            last_modified: client.last_modified,
            kyc_status: client.kyc_status,
            sales_agent: client.sales_agent,
            role: client.role,
            note: client.note,
            free_text: client.free_text,
            free_text_2: client.free_text_2,
            free_text_3: client.free_text_3,
            free_text_4: client.free_text_4,
            free_text_5: client.free_text_5,
            phone_2: client.phone_2,
            created_at: client.created_at,
            last_login: client.last_login,
            last_logout: client.last_logout,
            total_deposit: client.total_deposit,
            cfd_group_id: client.cfd_group_id,
            crypto_group_id: client.crypto_group_id,
            kyc_proof_of_identity_status: client.kyc_proof_of_identity_status,
            kyc_proof_of_residency_status: client.kyc_proof_of_residency_status,
            kyc_credit_debit_card_documentation_status:
              client.kyc_credit_debit_card_documentation_status,
            dod_status: client.dod_status,
            other_kyc_status: client.other_kyc_status,
            kyc_credit_debit_card_back_documentation_status:
              client.kyc_credit_debit_card_back_documentation_status,
            sales_status: client.sales_status,
            sales_status2: client.sales_status2,
            saving_account_id: client.saving_account_id,
            bonus: client.bonus,
            activation_status: client.activation_status,
            currency_code: client.currency_code,
            last_trade: client.last_trade,
            last_comment: client.last_comment,
            last_call: client.last_call,
            ftd_date: client.ftd_date,
            deposits: client.deposits,
            last_deposit_date: client.last_deposit_date,
            active: client.active,
            margin_call_notified_date: client.margin_call_notified_date,
            vpn_status: client.vpn_status,
            full_name: client.full_name,
            office: client.office,
            total_bonus: client.total_bonus,
            total_deposit_count: client.total_deposit_count,
            online: client.online,
            group: client.group,
            sales_status_answer: client.sales_status_answer,
          };
        });
      });
      // localStorage.setItem("clientsData", JSON.stringify(this.clients));
    } catch (error: any) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      console.log("fetchClients error", error);
      throw error;
    }
  }
  setSearchPhrase(searchPhrase: string) {
    this.searchPhrase = searchPhrase;
  }
  setSelectedRows(rows: number[]) {
    this.selectedRows = rows;
  }
  addClient(client: Partial<Client>) {
    this.clients.push({ ...client, _id: uniqueId() });
  }
  intervalFetchData = async () => {
    try {
      const intervalId = setInterval(() => {
        this.fetchClients();
      }, 30000);
      return () => clearInterval(intervalId);
    } catch (e) {
      throw e;
    }
  };
}

export default ClientStore;
