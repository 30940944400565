import axios from "axios";
import { API } from "../../utils/API";
import { CONSTANT_USER } from "../../hooks/api/useUser";

export interface ITradeUser {
	refresh: string;
	access: string;
}

export const ltdLogin = async (
	email: string,
	password: string
): Promise<ITradeUser | undefined> => {
	try {
		const response = await axios({
			method: "post",
			url: API.login,
			headers: {
				"Content-Type": "application/json",
			},
			data: {
				username: email,
				password,
			},
		});
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			throw new Error(
				`Failed to update client data: ${
					error.response ? error.response.data : error.message
				}`
			);
		}
		return undefined;
	}
};

export const GetUser = () => {
	// @ts-ignore
	return JSON.parse(window.localStorage.getItem(CONSTANT_USER));
};
