
export const enTranslations = {
    clients: {
        clients: "Clients",
        fastLogin: "Fast Login",
        selected: "Selected",
        deleteSelected: 'Delete Selected',
        table: {
            "_id": "Client ID",
            "country": "Country",
            "note": "Note",
            "free_text": "Free Text",
            "free_text_2": "Another Free Text",
            "free_text_3": "Yet Another Free Text",
            "free_text_4": "Yet Another Free Text",
            "free_text_5": "Yet Another Free Text",
            "campaign": "Campaign",
            "sub_campaign_key": "Sub-campaign Key",
            "phone": "Phone",
            "phone_2": "Another Phone",
            "email": "Email",
            "created_at": "Created At",
            "attribution_date": "Attribution Date",
            "balance": "Account Balance",
            "sa_balance": "SA Balance",
            "last_login": "Last Login",
            "last_logout": "Last Logout",
            "total_deposit": "Total Deposit",
            "role": "Role",
            "cfd_group_id": "CFD Group ID",
            "crypto_group_id": "Crypto Group ID",
            "kyc_status": "KYC Status",
            "kyc_proof_of_identity_status": "KYC Proof of Identity Status",
            "kyc_proof_of_residency_status": "KYC Proof of Residency Status",
            "kyc_credit_debit_card_documentation_status": "KYC Credit/Debit Card Documentation Status",
            "dod_status": "DOD Status",
            "other_kyc_status": "Other KYC Status",
            "kyc_credit_debit_card_back_documentation_status": "KYC Credit/Debit Card Back Documentation Status",
            "sales_status": "Sales Status",
            "sales_status2": "Another Sales Status",
            "saving_account_id": "Saving Account ID",
            "bonus": "Bonus",
            "activation_status": "Activation Status",
            "currency_code": "Currency Code",
            "last_trade": "Last Trade",
            "last_comment": "Last Comment",
            "last_call": "Last Call",
            "ftd_date": "FTD Date",
            "deposits": "Deposits",
            "last_deposit_date": "Last Deposit Date",
            "active": "Active",
            "margin_call_notified_date": "Margin Call Notified Date",
            "vpn_status": "VPN Status",
            "full_name": "Full Name",
            "sales_agent": "Sales Agent",
            "office": "Office",
            "total_bonus": "Total Bonus",
            "last_modified": "Last Modified",
            "total_deposit_count": "Total Deposit Count",
            "online": "Online",
            "group": "Group",
            "sales_status_answer": "Sales Status Answer"
        }
    },
    "CRM": "CRM",
    "Shop": "Shop",
    "Chat": "Chat",
    "Sales": "Sales",
    "Results": "Results",
    "Invoice": "Invoice",
    "Payment": "Payment",
    "Profile": "Profile",
    "Account": "Account",
    "Pricing": "Pricing",
    "Checkout": "Checkout",
    "Sessions": "Sessions",
    "Ecommerce": "Ecommerce",
    "Dashboards": "Dashboards",
    "Enrolled Courses": "Enrolled Courses",
    "Course Completed": "Course Completed",
    "Course in Progress": "Course in Progress",
    "Learning Management": "Learning Management",
    "Welcome Back! Watson": "Welcome Back! Watson",
    "Job Management": "Job Management",
    "User & Contact": "User & Contact",
    "User List": "User List",
    "User Grid": "User Grid",
    "Contact List": "Contact List",
    "Contact Grid": "Contact Grid",
    "Invoice List": "Invoice List",
    "Invoice Details": "Invoice Details",
    "Create Invoice": "Create Invoice",
    "Product Details": "Product Details",
    "Billing Address": "Billing Address",
    "Payment Complete": "Payment Complete",
    "Admin Ecommerce": "Admin Ecommerce",
    "Product List": "Product List",
    "Product Grid": "Product Grid",
    "Create Product": "Create Product",
    "Upcoming Task": "Upcoming Task",
    "Study Time Last Week": "Study Time Last Week",
    "Your Downloads": "Your Downloads",
    "Course Status": "Course Status"
};