import { CSSProperties } from "@mui/styled-engine";
import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import { FC, Fragment, useState, ReactNode } from "react";
import { Outlet } from "react-router";
import DashboardHeader from "../layout-parts/DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import DataFilter from "components/DateFilter";

// --------------------------------------------
type DashboardLayoutProps = {
  children?: ReactNode;
};
// --------------------------------------------

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
  const [sidebarCompact, setSidebarCompact] = useState(false);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);

  const handleCompactToggle = () => setSidebarCompact(!sidebarCompact);
  const handleMobileDrawerToggle = () =>
    setShowMobileSideBar((state) => !state);

  // dashboard body wrapper custom style
  const customStyle: CSSProperties = {
    width: `100%`,
  };

  return (
    <Fragment>

      <LayoutBodyWrapper sx={customStyle}>
        <DashboardHeader
          setShowSideBar={handleCompactToggle}
          setShowMobileSideBar={handleMobileDrawerToggle}
        />

        {children || <Outlet />}
      </LayoutBodyWrapper>
    </Fragment>
  );
};

export default DashboardLayout;
